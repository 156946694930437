<script>
  import { fade } from "svelte/transition";
  import { User } from "sveltefire";
  import { createEventDispatcher } from "svelte";
  import { Ripple } from "svelte-mui/src";
  import EditButton from "Components/UI/EditButton.svelte";
  import DeleteButton from "Components/UI/DeleteButton.svelte";
  import { albumForm, isEditing } from "./album-form.store.js";

  let dispatch = createEventDispatcher();

  export let album;
  export let index;

  function onEditClick() {
    albumForm.clear();
    albumForm.set(album);
    isEditing.set(true);
  }

  function onDeleteClick() {
    const a = {
      ref: album.ref,
      src: album.src
    };
    dispatch("delete", a);
  }
</script>

<style>
  .img {
    /* position: relative; */
    /* width: 100%; */
    height: 0;
    padding-bottom: 100%;
    cursor: pointer;
    background-position: center !important;
    background-size: cover !important;
    transition: 200ms;
  }

  .img:hover {
    opacity: 0.5;
    transition: 200ms;
  }

  article {
    text-align: center;
  }
</style>

<article in:fade={{ delay: (index + 1) * 100 + 200 }}>

  <div class="img" style="background: url({album.src})" on:click>
    <Ripple />
  </div>

  <div>
    <p>
      {album.title}
      <User>
        <span>
          <EditButton on:click={onEditClick} />
        </span>
        <span>
          <DeleteButton on:click={onDeleteClick} />
        </span>
      </User>
    </p>
  </div>

  <p>{album.year}</p>
</article>
