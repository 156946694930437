import { writable } from 'svelte/store';

const emptyContact = {
  website: {},
  facebook: {},
  instagram: {}
};

const formWritable = writable(emptyContact);

export const contactFormData = {
  subscribe: formWritable.subscribe,
  set: formWritable.set,
  clear: () => {
    isEditing.set(false);
    isAdding.set(false);
    formWritable.set(emptyContact);
  }
};
export const isEditing = writable(false);
export const isAdding = writable(false);
