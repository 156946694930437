<script>
  import { blur } from "svelte/transition";
  import Title from "Components/UI/Title.svelte";
  import { media } from "../media.store.js";

  export let src;
  export let gradientTop = "rgba(18, 18, 18, 0.5)";
  export let gradientBottom = "rgba(18, 18, 18, 1)";
  export let adjustToMobile = false;
  export let adjustToTablet = false;
</script>

<style>
  div {
    width: 100%;
    height: 90vh;
    background-size: cover !important;
    display: grid;
    align-items: center;
    justify-items: center;
  }

  .mobile-high {
    height: 70vh;
  }

  .mobile {
    height: 40vh;
  }

  .tablet-high {
    height: 80vh;
  }

  .tablet {
    height: 55vh;
  }
</style>

<div
  in:blur={{ duration: 1000, delay: 500, amount: 10 }}
  class:mobile-high={$media.mobile}
  class:mobile={$media.mobile && adjustToMobile}
  class:tablet-high={$media.tablet}
  class:tablet={$media.tablet && adjustToTablet}
  style="background: linear-gradient(0deg, {gradientBottom}, {gradientTop}),
  url({src}) no-repeat center center">
  <Title>
    <slot />
  </Title>
</div>
