<script>
  import { fade } from "svelte/transition";
  import { Doc } from "sveltefire";

  export let id;

  let path = "albums/" + id;
</script>

<style>
  .wrapper {
    display: grid;
    justify-items: center;
  }

  img {
    width: 100%;
    display: block;
  }

  article {
    padding: 1rem;
    display: grid;
    grid-template-columns: minmax(300px, 750px) auto;
    grid-gap: 2rem;
  }

  section {
    width: auto;
  }

  h1 {
    font-weight: 300;
    font-size: 2rem;
    margin: 0;
    margin-bottom: 2rem;
  }

  h2 {
    font-weight: 300;
    font-size: 1rem;
    margin: 0;
    margin-top: 2rem;
  }

  p {
    font-size: 1.3rem;
    margin: 0;
  }

  @media (max-width: 800px) {
    article {
      display: grid;
      grid-template-columns: 1fr;
      padding: 0;
    }

    img {
      max-width: 100%;
    }

    section {
      padding: 1rem;
    }
  }
</style>

<Doc {path} let:data={album}>

  <div class="wrapper">

    <article in:fade>
      <div class="img-container">
        <img src={album.src} alt="" />
      </div>

      <section>
        <h1>
          <h1>{album.title} {album.year}</h1>
        </h1>
        <div>
          {#each album.members as member}
            <p>{member}</p>
          {/each}
        </div>
        <div>
          <h2>Recorded at</h2>
          <p>{album.recordedAt}</p>
        </div>
        {#if album.recordedBy === album.mix && album.mix === album.master}
          <div>
            <h2>Recorded, mixed and mastered by</h2>
            <p>{album.recordedBy}</p>
          </div>
        {:else if album.mix === album.master}
          <div>
            <h2>Mixed and mastered by</h2>
            <p>{album.mix}</p>
          </div>
        {:else}
          <div>
            <h2>Recorded by</h2>
            <p>{album.recordedBy}</p>
          </div>
          <div>
            <h2>Mixed by</h2>
            <p>{album.mix}</p>
          </div>
          <div>
            <h2>Mastered by</h2>
            <p>{album.master}</p>
          </div>
        {/if}

        <div>
          <h2>Artwork</h2>
          <p>{album.artwork}</p>
        </div>
        <div>
          <h2>Label</h2>
          <p>{album.label}</p>
        </div>
      </section>
    </article>

  </div>
</Doc>
