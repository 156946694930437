<script>
  const firstYear = 2020;
  const today = new Date();
  const thisYear = today.getFullYear();
</script>

<style>
  footer {
    display: grid;
    align-content: center;
    height: 3rem;
    padding-left: 1rem;
    color: rgba(255, 255, 255, 0.5);
  }
</style>

<footer>
  {#if firstYear === thisYear}
    <div>&copy; {firstYear} October Tide</div>
  {:else}
    <div>&copy; {firstYear} - {thisYear} October Tide</div>
  {/if}
</footer>
