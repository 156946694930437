<script>
  import { slide } from "svelte/transition";
  import { Textfield, Button } from "svelte-mui/src";
  import { getContext, createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();
  const firestore = getContext("firebase").firestore();

  let video = {};

  function onSubmit() {
    firestore
      .collection("videos")
      .add(video)
      .then(clear)
      .catch(handleError);
  }

  function handleError(error) {
    console.log(error.message);
  }

  function clear() {
    video = {};
    dispatch("clear");
  }
</script>

<style>
  form {
    max-width: 500px;
    width: 100%;
  }

  .form-container {
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 2rem;
  }
</style>

<div class="form-container" transition:slide>
  <form on:submit|preventDefault={onSubmit}>
    <Textfield
      class="textfield-dark-theme"
      label="Youtube video ID"
      bind:value={video.youTubeId} />

    <section id="action-buttons">
      <Button type="submit" raised color="#388e3c">Save</Button>

      <Button
        type="button"
        color="rgba(255, 255, 255, 0.7)"
        outlined
        on:click={clear}>
        Cancel
      </Button>
    </section>
  </form>
</div>
