<script>
  import { fade } from "svelte/transition";
  import { User } from "sveltefire";
  import { Textfield, Button, Snackbar } from "svelte-mui/src";
  import { navigate } from "svelte-routing";

  let email;
  let password;
  let showLogin = true;
  let visible = false;
  let message = "";
  let bg = "";

  function authenticate(auth) {
    auth
      .signInWithEmailAndPassword(email, password)
      .then(() => navigate("/shows"))
      .catch(handleError);
  }

  function reset(auth) {
    auth
      .sendPasswordResetEmail(email)
      .then(response => {
        showLogin = true;
        visible = true;
        bg = "#388E3C";
        message = "Password reset email has been sent";
      })
      .catch(handleError);
  }

  function handleError(error) {
    visible = true;
    bg = "#D32F2F";
    message = error.message;
  }
</script>

<style>
  form {
    max-width: 500px;
    width: 100%;
  }

  div {
    padding: 16px;
    display: grid;
    align-items: center;
    justify-items: center;
  }
</style>

<Snackbar {bg} timeout="7" bottom bind:visible>
  {message}
  <span slot="action" />
</Snackbar>

<User let:auth>

  <div slot="signed-out" in:fade>
    <form on:submit|preventDefault={authenticate(auth)}>
      <Textfield
        label="Email"
        class="textfield-dark-theme"
        color="rgba(255, 255, 255, 0.87)"
        type="text"
        name="email"
        bind:value={email} />
      {#if showLogin}
        <Textfield
          label="Password"
          class="textfield-dark-theme"
          type="password"
          name="password"
          bind:value={password} />
        <Button raised color="#388e3c" type="submit">LOGIN</Button>
        <Button
          type="button"
          color="rgba(255, 255, 255, 0.7)"
          outlined
          on:click={() => (showLogin = false)}>
          Reset password
        </Button>
      {:else}
        <Button
          raised
          color="#388e3c"
          type="button"
          on:click={() => reset(auth)}>
          SEND RESET EMAIL
        </Button>
        <Button
          type="button"
          color="rgba(255, 255, 255, 0.7)"
          outlined
          on:click={() => (showLogin = true)}>
          Go to login
        </Button>
      {/if}
    </form>

  </div>

</User>
