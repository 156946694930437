<script>
  import { User } from "sveltefire";
  import { createEventDispatcher } from "svelte";
  import { fade } from "svelte/transition";

  import { showFormData, isEditing } from "./show-form.store.js";
  import { Button } from "svelte-mui/src";
  import Divider from "Components/UI/Divider.svelte";
  import DeleteButton from "Components/UI/DeleteButton.svelte";
  import EditButton from "Components/UI/EditButton.svelte";
  import { media } from "../media.store";

  export let id;
  export let date;
  export let venue;
  export let city;
  export let country;
  export let link;

  $: show = {
    id: id,
    date: date,
    venue: venue,
    city: city,
    country: country,
    link: link
  };

  export let index;
  export let listLength;

  let dispatch = createEventDispatcher();

  function getMonth(date) {
    const d = new Date(date);
    const month = d.toLocaleString("en-GB", { month: "short" });
    return month.replace(".", "");
  }

  function getDay(date) {
    const d = new Date(date);
    return d.getDate();
  }
  function getYear(date) {
    const d = new Date(date);
    return d.getFullYear();
  }

  function onEditClick() {
    showFormData.clear();
    isEditing.set(true);
    showFormData.set(show);
  }

  function onDeleteClick() {
    dispatch("delete");
  }

  function onMoreInfoClick() {
    window.open(show.link, "_blank");
  }
</script>

<style>
  section {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr auto;
    align-items: center;
    height: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .mobile {
    grid-template-columns: 1fr;
    height: auto;
    justify-items: center;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    grid-gap: 1rem;
  }

  #date {
    font-family: "Oswald";
    text-transform: uppercase;
    font-size: 1.2rem;
  }

  #venue {
    font-size: 1.5rem;
    font-weight: 300;
  }

  #city {
    font-size: 1.3rem;
    font-weight: 300;
  }

  .admin-button-container {
    display: grid;
    grid-template-columns: auto auto;
    justify-items: right;
  }

  .ticket-container {
    display: grid;
    justify-items: right;
  }
</style>

<section class:mobile={$media.mobile} in:fade={{ delay: (index + 1) * 100 }}>
  <div id="date">{getMonth(date)} {getDay(date)} {getYear(date)}</div>
  <div id="venue">{venue}</div>
  {#if $media.mobile}
    <div id="city">{city}, {country}</div>
  {:else}
    <div>
      <div id="city">{city}</div>
      <div>{country}</div>
    </div>
  {/if}
  {#if link}
    <div class="ticket-container">
      <Button
        outlined
        color="rgba(255, 255, 255, 0.7)"
        on:click={onMoreInfoClick}>
        More info
      </Button>
    </div>
  {:else}
    <div />
  {/if}
  <User>
    <div class="admin-button-container">
      <EditButton on:click={onEditClick} />
      <DeleteButton on:click={onDeleteClick} />
    </div>
  </User>
</section>

{#if index !== listLength - 1}
  <div in:fade={{ delay: (index + 1) * 100 }}>
    <Divider />
  </div>
{/if}
