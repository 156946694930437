<script>
  import EditButton from "Components/UI/EditButton.svelte";
  import DeleteButton from "Components/UI/DeleteButton.svelte";
  import ContactLink from "./ContactLink.svelte";
  import { contactFormData, isEditing } from "./contact-form.store.js";
  import { User } from "sveltefire";
  import { email, website, instagram, facebook } from "../icon-paths";
  import { getContext } from "svelte";
  import { fade } from "svelte/transition";

  export let contact;
  export let index;

  function deleteContact() {
    const storageRef = getContext("firebase").storage();
    const imageRef = storageRef.refFromURL(contact.imageUrl);
    imageRef.delete().then(() => {
      contact.ref.delete();
    });
  }
  function editContact() {
    contactFormData.clear();
    contactFormData.set(contact);
    isEditing.set(true);
  }
</script>

<style>
  article {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 2px;
    overflow: hidden;
  }

  .name {
    font-size: 2rem;
  }

  .role {
    font-size: 1.5rem;
    color: rgba(255, 255, 255, 0.7);
    padding-bottom: 1rem;
  }

  .buttons {
    display: grid;
    justify-items: center;
  }

  img {
    width: 100%;
  }
</style>

<article in:fade={{ delay: (index + 1) * 100 + 200 }}>
  <div class="photo">
    <img src={contact.imageUrl} alt="" />
  </div>
  <section style="padding: 1rem;">
    <div class="name">{contact.name}</div>
    <div class="role">{contact.role}</div>

    <User>
      <div class="buttons">
        <div>
          <EditButton on:click={editContact} />
          <DeleteButton on:click={deleteContact} />
        </div>
      </div>
    </User>

    {#if contact.email}
      <ContactLink path={email} label={contact.email} />
    {/if}
    {#if contact.website && contact.website.label}
      <ContactLink
        path={website}
        label={contact.website.label}
        link={contact.website.url} />
    {/if}
    {#if contact.instagram && contact.instagram.label}
      <ContactLink
        path={instagram}
        label={contact.instagram.label}
        link={contact.instagram.url} />
    {/if}
    {#if contact.facebook && contact.facebook.label}
      <ContactLink
        path={facebook}
        label={contact.facebook.label}
        link={contact.facebook.url} />
    {/if}

  </section>
</article>
