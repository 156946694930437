<script>
  import { Textfield, Button, Datefield } from "svelte-mui/src";
  import { showFormData, isEditing, isAdding } from "./show-form.store.js";
  import { getContext, onDestroy } from "svelte";
  import { slide } from "svelte/transition";

  let show;
  let ref;

  $: if ($isAdding || $isEditing)
    window.scrollTo({ top: 0, behavior: "smooth" });

  $: textFields = [
    { label: "Venue", key: "venue", value: show.venue },
    { label: "City", key: "city", value: show.city },
    { label: "Country", key: "country", value: show.country },
    { label: "Link (more info / tickets)", key: "link", value: show.link }
  ];

  const showCollection = getContext("firebase")
    .firestore()
    .collection("shows");

  const unsubscribe = showFormData.subscribe(data => {
    show = prepareForForm(data);
  });

  onDestroy(() => {
    clear();
    unsubscribe();
  });

  function saveShow() {
    show = prepareForFirestore(show);
    showCollection.add(show);
    clear();
  }

  function updateShow() {
    const id = show.id;
    show = prepareForFirestore(show);
    showCollection.doc(id).update(show);
    clear();
  }

  function prepareForFirestore(item) {
    let jsDate = new Date(item.date);
    item.date = jsDate.getTime();
    if (item.id) delete item.id;
    return { ...item };
  }

  function prepareForForm(item) {
    if (item.date) {
      let d = new Date(item.date);
      item.date = d.toISOString().slice(0, 10);
    }
    return { ...item };
  }

  function clear() {
    showFormData.clear();
  }

  function updateValue(e, key) {
    show[key] = e.target.value;
  }
</script>

<style>
  .form {
    max-width: 500px;
    width: 100%;
  }

  .form-container {
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 2rem;
  }
</style>

<div class="form-container" transition:slide>
  <div class="form">

    <Datefield
      class="textfield-dark-theme"
      label="Date"
      message="YYYY-MM-DD"
      bind:value={show.date} />

    {#each textFields as { label, value, key }}
      <Textfield
        class="textfield-dark-theme"
        {label}
        {value}
        on:input={e => updateValue(e, key)} />
    {/each}

    {#if $isAdding}
      <Button raised color="#388e3c" on:click={saveShow}>Save</Button>
    {/if}

    {#if $isEditing}
      <Button raised color="#388e3c" on:click={updateShow}>Update</Button>
    {/if}

    <Button color="rgba(255, 255, 255, 0.7)" outlined on:click={clear}>
      Cancel
    </Button>

  </div>
</div>
