<script>
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";
  import DeleteButton from "Components/UI/DeleteButton.svelte";
  import { User } from "sveltefire";

  export let video;
  let iframeElement;

  onMount(() => {
    iframeElement.style.width = "100%";
    updateHeight();
  });

  function updateHeight() {
    let width = iframeElement.clientWidth;
    iframeElement.style.height = width * 0.5625 + "px";
  }

  function onDelete() {
    video.ref.delete();
  }
</script>

<div in:fade>
  <iframe
    bind:this={iframeElement}
    title="Music video"
    src="https://www.youtube.com/embed/{video.youTubeId}"
    frameborder="0"
    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen />
  <User>
    <DeleteButton on:click={onDelete} />
  </User>
</div>
