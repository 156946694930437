<script>
  import { Doc, User } from "sveltefire";
  import EditButton from "Components/UI/EditButton.svelte";
  import { Button } from "svelte-mui/src";
  import { fade } from "svelte/transition";

  export let fadeInDelay;

  let path = "content/bio";
  let isEditing = false;
  let bioContent;

  function onEdit(editedBio) {
    bioContent = editedBio;
    isEditing = true;
  }

  function onSave(ref) {
    ref.update(bioContent);
    isEditing = false;
  }

  function cancel() {
    isEditing = false;
  }
</script>

<style>
  p {
    max-width: 700px;
    width: 100%;
    white-space: pre-wrap;
  }

  article {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    padding: 1rem;
    text-align: justify;
  }
</style>

<Doc {path} let:data={bio} let:ref>
  <article in:fade={{ delay: fadeInDelay }}>
    {#if isEditing}
      <textarea name="bio" cols="100" rows="30" bind:value={bioContent.text} />
      <div>
        <Button raised color="#388E3C" on:click={onSave(ref)}>Save</Button>
        <Button color="rgba(255, 255, 255, 0.7)" outlined on:click={cancel}>
          Cancel
        </Button>
      </div>
    {:else}
      <p>{bio.text}</p>

      <User>
        <section>

          <EditButton on:click={onEdit(bio)} />
        </section>
      </User>
    {/if}

  </article>
</Doc>
