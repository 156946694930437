<script>
  import { fade } from "svelte/transition";
  import { User, Collection } from "sveltefire";
  import Bio from "Components/about/Bio.svelte";
  import PressImage from "Components/press/PressImage.svelte";
  import FileUploader from "Components/press/FileUploader.svelte";
  import PageDivider from "Components/UI/PageDivider.svelte";
  import Fab from "Components/UI/Fab.svelte";

  let isAdding = false;

  const logoPressItem = {
    hiRes: {
      name: "octobertide_logos.zip",
      url:
        "https://firebasestorage.googleapis.com/v0/b/october-tide.appspot.com/o/press%2Foctobertide_logos.zip?alt=media&token=f9674068-cc3e-456d-9d3c-a7a2a6186bd8"
    },
    thumbnail: {
      name: "logo_thumbnail.jpg",
      url:
        "https://firebasestorage.googleapis.com/v0/b/october-tide.appspot.com/o/press%2Flogo_thumbnail.jpg?alt=media&token=f30a4570-6de4-4c49-a391-fba788b3d5c0"
    }
  };

  const logoIndex = 0;

  $: if (isAdding) window.scrollTo({ top: 0, behavior: "smooth" });
</script>

<style>
  section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    padding-left: 1rem;
  }

  div {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  @media (max-width: 843px) {
    section {
      grid-template-columns: 1fr;
      grid-gap: 0;
      padding-left: 0;
    }
  }
</style>

<svelte:head>
  <title>October Tide | Press</title>
</svelte:head>

<article in:fade>
  <User>
    {#if isAdding}
      <FileUploader on:hide-form={() => (isAdding = false)} />
      <PageDivider />
    {/if}
  </User>
  <section>

    <Collection path="press" let:data={pressItems}>
      <div>
        <PressImage pressItem={logoPressItem} index={logoIndex} />
        {#each pressItems as pressItem, index}
          <PressImage {pressItem} index={index + 1} />
        {/each}
      </div>
      <Bio fadeInDelay={pressItems.length * 100 + 200} />
    </Collection>

  </section>
</article>

{#if !isAdding}
  <User>
    <Fab on:click={() => (isAdding = true)} />
  </User>
{/if}
