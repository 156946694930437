import { writable } from 'svelte/store';

const emptyAlbum = { members: [] };

const writeable = writable({ ...emptyAlbum });

export const albumForm = {
  subscribe: writeable.subscribe,
  set: writeable.set,
  clear: () => {
    isEditing.set(false);
    isAdding.set(false);
    writeable.set({ ...emptyAlbum });
  }
};
export const isEditing = writable(false);
export const isAdding = writable(false);
