<script>
  import { Icon, Button } from "svelte-mui/src";

  export let path;
  export let url;

  function onClick() {
    window.open(url, "_blank");
  }
</script>

<Button icon style="color: var(--primary-white)" on:click={onClick}>
  <Icon {path} />
</Button>
