<script>
  import DownloadButton from "Components/UI/DownloadButton.svelte";
  import DeleteButton from "Components/UI/DeleteButton.svelte";
  import { getContext } from "svelte";
  import { User } from "sveltefire";
  import { media } from "../media.store";
  import { blur } from "svelte/transition";

  export let pressItem;
  export let index;

  let hovering = false;
  let downloadElement;

  function mouseEnter() {
    hovering = true;
  }

  function mouseLeave() {
    hovering = false;
  }

  function onDownloadClick() {
    fetch(pressItem.hiRes.url)
      .then(response => response.blob())
      .then(blob => {
        const blobUrl = window.URL.createObjectURL(blob);
        downloadElement.href = blobUrl;
        // TODO: set filename
        downloadElement.click();
        console.log(blobUrl);
      });
  }

  function deletePressItem() {
    const storage = getContext("firebase").storage();
    const hiResImageRef = storage.refFromURL(pressItem.hiRes.url);
    const thumbnailImageRef = storage.refFromURL(pressItem.thumbnail.url);

    hiResImageRef
      .delete()
      .then(() => thumbnailImageRef.delete())
      .then(() => pressItem.ref.delete());
  }
</script>

<style>
  img {
    width: 100%;
    transition: 200ms;
    transition-delay: 0ms;
  }

  section {
    position: relative;
  }

  .translucent {
    opacity: 0.5;
    filter: blur(2px);
    transition: 200ms;
    transition-delay: 0ms;
  }

  div {
    position: absolute;
  }

  @media (min-width: 844px) {
    div {
      top: 50%;
      width: 100%;
      display: grid;
      justify-items: center;
    }
  }

  @media (max-width: 843px) {
    div {
      bottom: 0;
      right: 0;
    }
  }

  a {
    display: none;
  }
</style>

<section
  on:mouseenter={mouseEnter}
  on:mouseleave={mouseLeave}
  in:blur={{ delay: (index + 1) * 100 + 200 }}>
  <img
    class:translucent={hovering}
    src={pressItem.thumbnail.url}
    alt="Press image" />
  <div>
    {#if $media.desktop && hovering}
      <DownloadButton desktop on:click={onDownloadClick} />
      <User>
        <DeleteButton on:click={deletePressItem} />
      </User>
    {:else if !$media.desktop}
      <User>
        <DeleteButton on:click={deletePressItem} />
      </User>
      <DownloadButton on:click={onDownloadClick} />
    {/if}
  </div>

  <a
    bind:this={downloadElement}
    href={pressItem.hiRes.url}
    download={pressItem.hiRes.name}>
    Download Link
  </a>
</section>
