<script>
  import { Button } from "svelte-mui/src";
  import { navigate } from "svelte-routing";
  export let auth;

  function logOut() {
    auth.signOut().then(() => {
      navigate("/");
    });
  }
</script>

<Button color="white" on:click={logOut}>Logout</Button>
