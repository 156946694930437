import { readable } from 'svelte/store';

// mobile max 414px
// tablet max 843px
// desktop min 843px

function isMobile(width) {
  if (width <= 470) return true;
  else return false;
}

function isTablet(width) {
  if (width > 470 && width <= 1050) return true;
  else return false;
}

function isDesktop(width) {
  if (width > 1050) return true;
  else return false;
}

const initialWidth = window.innerWidth;

let mediaQueries = width => {
  return {
    mobile: isMobile(width),
    tablet: isTablet(width),
    desktop: isDesktop(width)
  };
};

export const media = readable(mediaQueries(initialWidth), set => {
  window.addEventListener('resize', () => {
    set(mediaQueries(window.innerWidth));
  });
});
