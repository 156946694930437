<script>
  import { createEventDispatcher, getContext } from "svelte";
  import { navigate, links } from "svelte-routing";
  import { Ripple } from "svelte-mui/src";
  import { ROUTER } from "svelte-routing/src/contexts";

  const { activeRoute } = getContext(ROUTER);
  const dispatch = createEventDispatcher();

  export let href;
  export let label;

  let active = false;

  $: if ($activeRoute) {
    if ($activeRoute.uri === "/" + href) active = true;
    else active = false;
  }

  function goTo() {
    dispatch("toggle-sidepanel");
    navigate(href);
  }
</script>

<style>
  a {
    box-sizing: border-box;
    border-bottom: none;
    font-family: "Oswald";
    font-weight: 400;
    text-decoration: none !important;
    color: rgba(255, 255, 255, 0.87) !important;
    height: 100%;
    width: auto;
    padding: 0;
    margin: 0;
    display: grid;
    align-items: center;
    justify-items: center;
  }

  div {
    display: grid;
    align-items: center;
    justify-items: center;
    height: 100%;
    cursor: pointer;
    width: 80px;
    transition: 200ms;
  }

  div:hover {
    background: rgba(255, 255, 255, 0.1);
    transition: 200ms;
  }

  .active {
    padding-top: 0.25em;
    border-bottom: 0.25em rgba(255, 255, 255, 0.5) solid;
    transition: 200ms;
  }

  @media (max-width: 1051px) {
    a {
      width: 100%;
      padding-top: 8px;
      padding-bottom: 8px;
      height: 100%;
    }
    div {
      width: 100%;
    }

    .active {
      box-sizing: border-box;
      padding-top: 8px;
      padding-bottom: 8px;
      border-bottom: none;
      background: rgba(255, 255, 255, 0.1);
    }
  }
</style>

<div use:links on:click={goTo}>
  <Ripple />
  <a {href} class:active>{label}</a>
</div>
