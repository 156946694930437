<script>
  import { getContext } from "svelte";
  import { onDestroy } from "svelte";
  import { Textfield, Button } from "svelte-mui/src";
  import { albumForm, isAdding, isEditing } from "./album-form.store.js";
  import AddButton from "Components/UI/AddButton.svelte";
  import DeleteButton from "Components/UI/DeleteButton.svelte";
  import { slide } from "svelte/transition";

  let album = { members: [] };
  let workingMember;

  $: if ($isAdding || $isEditing)
    window.scrollTo({ top: 0, behavior: "smooth" });

  const unsubscribe = albumForm.subscribe(data => {
    album = { ...data };
  });

  onDestroy(() => {
    clear();
    unsubscribe();
  });

  let files;
  let file;
  $: if (files) file = files[0];

  let albumCollection = getContext("firebase")
    .firestore()
    .collection("albums");
  let storageRef = getContext("firebase")
    .storage()
    .ref();

  function saveAlbum() {
    storageRef
      .child("albums/" + file.name)
      .put(file)
      .then(upload => {
        upload.ref.getDownloadURL().then(url => {
          album.src = url;

          albumCollection.add(album).then(() => {
            clear();
          });
        });
      });
  }

  function updateAlbum() {
    let albumRef = album.ref;
    album = prepareForFirebase(album);
    albumRef.update(album).then(() => clear());
  }

  function prepareForFirebase(item) {
    if (item.id) delete item.id;
    if (item.ref) delete item.ref;
    return { ...item };
  }

  function add() {
    album.members = [...album.members, workingMember];
    workingMember = null;
  }

  function deleteMember(member) {
    album.members = album.members.filter(m => m !== member);
  }

  function clear() {
    albumForm.clear();
  }
</script>

<style>
  #form {
    grid-area: form;
  }
  #members {
    grid-area: members;
  }
  #cover {
    grid-area: cover;
  }
  #action-buttons {
    grid-area: buttons;
  }

  article {
    padding: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 7rem;
    grid-row-gap: 2rem;
    grid-template-areas:
      "form members"
      "form members"
      "form members"
      "form cover"
      "buttons .";
  }

  @media (max-width: 414px) {
    article {
      grid-template-columns: 1fr;
      grid-template-areas:
        "form"
        "members"
        "cover"
        "buttons";
    }
  }

  .lineup-container,
  .line-up,
  .member-container {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-gap: 1rem;
  }
</style>

<article transition:slide>
  <section id="form">
    <Textfield
      class="textfield-dark-theme"
      label="Title"
      bind:value={album.title} />
    <Textfield
      class="textfield-dark-theme"
      type="number"
      label="Year"
      bind:value={album.year} />
    <Textfield
      class="textfield-dark-theme"
      label="Recorded at"
      bind:value={album.recordedAt} />
    <Textfield
      class="textfield-dark-theme"
      label="Recorded by"
      bind:value={album.recordedBy} />
    <Textfield
      class="textfield-dark-theme"
      label="Mixed by"
      bind:value={album.mix} />
    <Textfield
      class="textfield-dark-theme"
      label="Mastered by"
      bind:value={album.master} />
    <Textfield
      class="textfield-dark-theme"
      label="Artwork by"
      bind:value={album.artwork} />
    <Textfield
      class="textfield-dark-theme"
      label="Label"
      bind:value={album.label} />
  </section>

  <section id="members">

    <div class="lineup-container">
      <h1>Line-up</h1>
      <div class="line-up">
        <Textfield
          class="textfield-dark-theme"
          label="Name"
          bind:value={workingMember} />
        <div>
          <AddButton on:click={add} />
        </div>
      </div>
    </div>
    {#each album.members as member}
      <div class="member-container">
        <p>{member}</p>
        <DeleteButton on:click={() => deleteMember(member)} />
      </div>
    {/each}

  </section>

  <section id="cover">
    <h1>Cover</h1>
    {#if $isAdding}
      <input type="file" bind:files required />
    {/if}
    {#if $isEditing}
      <img src={album.src} width="200" height="200" alt="Album Cover" />
    {/if}
  </section>

  <section id="action-buttons">
    {#if $isAdding}
      <Button raised color="#388e3c" on:click={saveAlbum}>Save</Button>
    {/if}

    {#if $isEditing}
      <Button raised color="#388e3c" on:click={updateAlbum}>Update</Button>
    {/if}

    <Button color="rgba(255, 255, 255, 0.7)" outlined on:click={clear}>
      Cancel
    </Button>
  </section>
</article>
