<script>
  import { fade } from "svelte/transition";
  import { User } from "sveltefire";
  import ContactForm from "Components/contact/ContactForm.svelte";
  import ContactGrid from "Components/contact/ContactGrid.svelte";
  import PageDivider from "Components/UI/PageDivider.svelte";
  import Fab from "Components/UI/Fab.svelte";
  import {
    contactFormData,
    isAdding,
    isEditing
  } from "../components/contact/contact-form.store.js";

  function onFabClick() {
    contactFormData.clear();
    isAdding.set(true);
  }
</script>

<svelte:head>
  <title>October Tide | Contact</title>
</svelte:head>

<article in:fade>
  <User>
    {#if $isAdding || $isEditing}
      <ContactForm />
      <PageDivider />
    {/if}
  </User>
  <ContactGrid />
</article>
<User>
  {#if !$isAdding && !$isEditing}
    <Fab on:click={onFabClick} />
  {/if}
</User>
