<script>
  import { FirebaseApp } from "sveltefire";
  import { Router, Route } from "svelte-routing";
  import Nav from "Components/UI/Nav.svelte";
  import Album from "Components/music/Album.svelte";
  import Footer from "Components/UI/Footer.svelte";
  import firebase from "firebase/app";
  import "firebase/firestore";
  import "firebase/auth";
  import "firebase/storage";
  import "firebase/analytics";

  // ROUTE IMPORTS
  import Home from "Routes/Home.svelte";
  import About from "Routes/About.svelte";
  import Shows from "Routes/Shows.svelte";
  import Login from "Routes/Login.svelte";
  import Press from "Routes/Press.svelte";
  import Music from "Routes/Music.svelte";
  import Contact from "Routes/Contact.svelte";

  export let url = "";

  const routes = [
    { path: "/", component: Home },
    { path: "about", component: About },
    { path: "shows", component: Shows },
    { path: "login", component: Login },
    { path: "press", component: Press },
    { path: "music", component: Music },
    { path: "contact", component: Contact }
  ];

  const firebaseConfig = {
    apiKey: "AIzaSyBf7ZLZ5JSSqp6hsQgRatiaOjULmyXkm9I",
    authDomain: "october-tide.firebaseapp.com",
    databaseURL: "https://october-tide.firebaseio.com",
    projectId: "october-tide",
    storageBucket: "october-tide.appspot.com",
    messagingSenderId: "456259893177",
    appId: "1:456259893177:web:23f889783f74ec33dca868",
    measurementId: "G-5GED6478MY"
  };

  firebase.initializeApp(firebaseConfig);
</script>

<style>
  @import url("https://fonts.googleapis.com/css?family=Questrial&display=swap");
  @import url("https://fonts.googleapis.com/css?family=Anton|Cabin:400,500,600,700|Heebo:300,400,500,700|Montserrat:300,400,500,600,700,800|Muli:300,300i,400,500,700,800|Oswald:300,400,500,600,700|Roboto+Condensed:300,400,700&display=swap");

  :global(:root) {
    --nav-height: 4rem;
    --primary-white: rgba(255, 255, 255, 0.87);
    --body-background: #121212;
    --app-bar-background: rgba(33, 33, 33, 0.99);
  }

  :global(body) {
    font-family: "Roboto Condensed";
    font-weight: 400;
    margin: 0;
    padding: 0;
    background: var(--body-background);
    color: rgba(255, 255, 255, 0.87);
    height: fit-content;
  }

  :global(h1) {
    font-family: "Oswald";
    font-size: 2rem;
  }

  :global(.textfield-dark-theme) {
    --label: rgba(146, 146, 146, 1);
    --color: rgba(255, 255, 255, 0.87);
    --primary: #388e3c !important;
  }

  div {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    overflow: auto;
  }

  main {
    padding-top: var(--nav-height);
    width: 100%;
  }
</style>

<svelte:head>
  <title>October Tide</title>
</svelte:head>

<FirebaseApp {firebase} analytics>
  <Router {url}>
    <Nav />
    <div>

      <main>
        {#each routes as route}
          <Route path={route.path} component={route.component} />
        {/each}
        <Route path="albums/:id" let:params>
          <Album id={params.id} />
        </Route>
      </main>
      <Footer />
    </div>

  </Router>
</FirebaseApp>
