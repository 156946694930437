<script>
  import Bio from "Components/about/Bio.svelte";
  import Display from "Components/UI/Display.svelte";

  let bioItem;
  let src = "images/band.jpg";

  function onEdit(event) {
    bioItem = event.detail;
  }
</script>

<style>
  section {
    margin-top: -150px;
  }

  @media (max-width: 1050px) {
    section {
      margin-top: -100px;
    }
  }

  @media (max-width: 700px) {
    section {
      margin-top: -50px;
    }
  }
</style>

<svelte:head>
  <title>October Tide | About</title>
</svelte:head>

<Display
  {src}
  adjustToMobile
  adjustToTablet
  gradientTop="rgba(18, 18, 18, 0)"
  gradientBottom="rgba(18, 18, 18, 1)" />
<section>
  <Bio on:edit={onEdit} fadeInDelay={1500} />
</section>
