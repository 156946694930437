<script>
  import { fade } from "svelte/transition";
  import Logo from "./Logo.svelte";
  import NavButton from "./NavButton.svelte";
  import LogoutButton from "./LogoutButton.svelte";
  import { User } from "sveltefire";
  import { getContext } from "svelte";
  import { navigate } from "svelte-routing";
  import { ROUTER } from "svelte-routing/src/contexts";
  import MenuButton from "Components/UI/MenuButton.svelte";
  import { Sidepanel } from "svelte-mui/src";
  import { media } from "../media.store.js";
  import { instagram, facebook, spotify, youtube, cart } from "../icon-paths";
  import IconLink from "./IconLink.svelte";

  const { activeRoute } = getContext(ROUTER);

  let currentRoute;
  let scrollY;
  let showSidepanel = false;

  $: if ($activeRoute) {
    currentRoute = $activeRoute.uri;
  }

  let navButtons = [
    { href: "/about", label: "About" },
    { href: "/shows", label: "Shows" },
    { href: "/press", label: "Press" },
    { href: "/music", label: "Music" },
    { href: "/contact", label: "Contact" }
  ];

  function onLogoClick() {
    navigate("/");
    showSidepanel = false;
  }
</script>

<style>
  :global(:root) {
    --sidepanel-header-height: 150px;
  }

  .app-bar {
    position: fixed;
    z-index: 1;
    width: 100vw;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: center;
    height: var(--nav-height);
    background: var(--app-bar-background);
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.25);
    transition: 300ms;
  }

  .logo-container {
    padding: 1rem;
  }

  img {
    max-width: 100%;
  }

  .mobile-nav {
    display: grid;
  }

  .sidepanel-container {
    height: 100%;
    background: var(--body-background);
  }

  .transparent {
    background: transparent;
    box-shadow: none;
    transition: 300ms;
  }

  .desktop-nav-buttons {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, 80px);
    justify-content: center;
    padding-right: 16px;
  }

  .icon-link-container {
    display: grid;
    grid-template-columns: repeat(5, auto);
    padding-right: 2rem;
    justify-content: right;
  }

  @media (max-width: 1050px) {
    .app-bar {
      grid-template-columns: auto auto 1fr;
    }

    .icon-link-container {
      padding-right: 1rem;
    }
  }

  @media (max-width: 470px) {
    .app-bar {
      grid-template-columns: auto 1fr;
    }
  }

  .menu-container {
    padding-left: 1rem;
  }
</style>

<svelte:window bind:scrollY />

{#if !$media.desktop}
  <Sidepanel bind:visible={showSidepanel}>
    <div class="sidepanel-container">
      <div class="logo-container">
        <img src="images/logo.png" alt="" on:click={onLogoClick} />
      </div>
      <nav class="mobile-nav">
        {#each navButtons as button}
          <NavButton
            href={button.href}
            label={button.label.toUpperCase()}
            on:toggle-sidepanel={() => (showSidepanel = !showSidepanel)} />
        {/each}
      </nav>
    </div>
  </Sidepanel>
{/if}

<nav
  class="app-bar"
  class:transparent={scrollY < 200 && (currentRoute === '/' || currentRoute === 'about')}
  in:fade={{ delay: 200 }}>

  {#if !$media.desktop}
    <div class="menu-container" in:fade>
      <MenuButton on:click={() => (showSidepanel = true)} />
    </div>
  {/if}

  {#if !$media.mobile}
    <Logo />
  {/if}

  {#if $media.desktop}
    <div class="desktop-nav-buttons" in:fade>
      {#each navButtons as button}
        <NavButton href={button.href} label={button.label.toUpperCase()} />
      {/each}
    </div>
  {/if}

  <div class="icon-link-container">
    <User let:auth>
      <LogoutButton {auth} />
      <div slot="signed-out">
        <IconLink
          path={instagram}
          url="https://www.instagram.com/octobertideofficial/" />
        <IconLink
          path={facebook}
          url="https://www.facebook.com/octobertideband/" />
        <IconLink
          path={spotify}
          url="https://open.spotify.com/artist/4Ttdsj7ypWvHOhephBvXAl" />
        <IconLink
          path={youtube}
          url="https://www.youtube.com/channel/UCn4t20fRD08rXXzZHd8FlUg" />
        <IconLink path={cart} url="https://octobertide.bigcartel.com/" />
      </div>
    </User>
  </div>

</nav>
