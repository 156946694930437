<script>
  import { User } from "sveltefire";
  import { fade } from "svelte/transition";
  import AlbumGrid from "Components/music/AlbumGrid.svelte";
  import AlbumForm from "Components/music/AlbumForm.svelte";
  import VideoGrid from "Components/music/VideoGrid.svelte";
  import VideoForm from "Components/music/VideoForm.svelte";
  import PageDivider from "Components/UI/PageDivider.svelte";
  import { Menu, Menuitem, Button, Icon } from "svelte-mui/src";
  import {
    albumForm,
    isAdding,
    isEditing
  } from "../components/music/album-form.store.js";

  let addingVideo = false;

  $: if (addingVideo) window.scrollTo({ top: 0, behavior: "smooth" });

  function onFabClick() {
    albumForm.clear();
    isAdding.set(true);
  }
</script>

<style>
  section {
    position: fixed;
    bottom: 24px;
    right: 24px;
  }

  h1 {
    padding-left: 1rem;
  }
</style>

<svelte:head>
  <title>October Tide | Music</title>
</svelte:head>

<User>
  {#if addingVideo}
    <VideoForm on:clear={() => (addingVideo = false)} />
  {/if}
  {#if $isAdding || $isEditing}
    <AlbumForm />
  {/if}
  {#if $isAdding || $isEditing || addingVideo}
    <PageDivider />
  {/if}
</User>

<h1 in:fade={{ delay: 200 }}>Discography</h1>
<AlbumGrid />

<h1 in:fade={{ delay: 1000 }}>Videography</h1>
<VideoGrid />

<User>
  {#if !$isAdding && !$isEditing && !addingVideo}
    <section>
      <Menu origin="bottom right">
        <div slot="activator">
          <Button color="#388E3C" icon fab raised on:click>
            <Icon path="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
          </Button>
        </div>

        <Menuitem on:click={onFabClick}>Album</Menuitem>
        <Menuitem on:click={() => (addingVideo = true)}>Video</Menuitem>
      </Menu>
    </section>
  {/if}
</User>
