<script>
  import { fade } from "svelte/transition";
  import Fab from "Components/UI/Fab.svelte";
  import { User } from "sveltefire";
  import ShowList from "Components/shows/ShowList.svelte";
  import ShowForm from "Components/shows/ShowForm.svelte";
  import PageDivider from "Components/UI/PageDivider.svelte";
  import {
    isEditing,
    isAdding,
    showFormData
  } from "../components/shows/show-form.store.js";

  function onFabClick() {
    showFormData.clear();
    isAdding.set(true);
  }
</script>

<svelte:head>
  <title>October Tide | Shows</title>
</svelte:head>

<article in:fade>
  <User>
    {#if $isAdding || $isEditing}
      <ShowForm />
      <PageDivider />
    {/if}
  </User>
  <ShowList heading="Upcoming shows" upcoming="true" />
  <ShowList heading="Past shows" />
</article>
<User>
  {#if !$isAdding && !$isEditing}
    <Fab on:click={onFabClick} />
  {/if}
</User>
