<script>
  import { Icon } from "svelte-mui/src";

  export let link;
  export let label;
  export let path;
</script>

<style>
  div {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-column-gap: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  a {
    color: var(--primary-white);
  }
</style>

<div>

  <Icon {path} />
  {#if link}
    <a href={link} target="_blank">{label}</a>
  {:else}{label}{/if}

</div>
