<script>
  import { Collection } from "sveltefire";
  import Person from "./Person.svelte";

  const path = "contacts";
  const query = ref => ref.orderBy("priority");
</script>

<style>
  article {
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 400px));
    gap: 1rem;
  }
</style>

<article>
  <Collection {path} let:data={contacts} {query}>
    {#each contacts as contact, index (contact)}
      <Person {contact} {index} />
    {/each}
  </Collection>
</article>
