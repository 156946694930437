<script>
  import YouTube from "Components/UI/YouTube.svelte";
  import { Collection } from "sveltefire";
</script>

<style>
  div {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    grid-gap: 24px;
    padding: 24px;
  }

  @media (max-width: 415px) {
    div {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
  }
</style>

<div>
  <Collection path="videos" let:data={videos}>
    {#each videos as video}
      <YouTube {video} />
    {/each}
  </Collection>

</div>
