<script>
  import { Textfield, Button } from "svelte-mui/src";
  import { getContext, onDestroy } from "svelte";
  import EditButton from "Components/UI/EditButton.svelte";
  import {
    contactFormData,
    isAdding,
    isEditing
  } from "./contact-form.store.js";
  import { slide } from "svelte/transition";

  $: if ($isAdding || $isEditing)
    window.scrollTo({ top: 0, behavior: "smooth" });

  let contact;
  let showFileUploader = true;

  let files;
  let file;
  $: if (files) file = files[0];

  const unsubscribe = contactFormData.subscribe(contactData => {
    contact = contactData;
    if (contact.imageUrl) showFileUploader = false;
  });

  onDestroy(() => {
    clear();
    unsubscribe();
  });

  const contactCollection = getContext("firebase")
    .firestore()
    .collection("contacts");

  const storage = getContext("firebase").storage();
  let storageRef = storage.ref();

  function onContactSave() {
    uploadNewImage()
      .then(getImageUrl)
      .then(addContact)
      .then(clear);
  }

  function getImageUrl(uploadResponse) {
    return uploadResponse.ref.getDownloadURL();
  }

  function addContact(imageUrl) {
    contact.imageUrl = imageUrl;
    if (contact.priority) item.priority = null;
    return contactCollection.add(contact);
  }

  function onContactUpdate() {
    if (file) {
      removeOldImage()
        .then(uploadNewImage)
        .then(getImageUrl)
        .then(updateContact)
        .then(clear);
    } else {
      updateContact().then(clear);
    }
  }

  function prepareForFirestore(item) {
    if (item.id) delete item.id;
    if (item.ref) delete item.ref;
    if (!item.priority) item.priority = null;
    return { ...item };
  }

  function removeOldImage() {
    return storage.refFromURL(contact.imageUrl).delete();
  }

  function uploadNewImage() {
    return storageRef.child("contacts/" + file.name).put(file);
  }

  function updateContact(newImageUrl = null) {
    const contactRef = contact.ref;
    if (newImageUrl) contact.imageUrl = newImageUrl;
    contact = prepareForFirestore(contact);
    return contactRef.update(contact);
  }

  function clear() {
    contactFormData.clear();
  }
</script>

<style>
  div {
    max-width: 500px;
    width: 100%;
  }

  section {
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 2rem;
    grid-gap: 1rem;
  }

  img {
    max-width: 300px;
    width: 100%;
  }
</style>

<section transition:slide>
  <div>
    <Textfield
      class="textfield-dark-theme"
      label="Name"
      bind:value={contact.name} />
    <Textfield
      class="textfield-dark-theme"
      label="Email"
      type="email"
      bind:value={contact.email} />
    <Textfield
      class="textfield-dark-theme"
      label="Role"
      bind:value={contact.role} />
    <Textfield
      class="textfield-dark-theme"
      label="Website label"
      bind:value={contact.website.label} />
    <Textfield
      class="textfield-dark-theme"
      label="Website url"
      bind:value={contact.website.url} />
    <Textfield
      class="textfield-dark-theme"
      label="Facebook label"
      bind:value={contact.facebook.label} />
    <Textfield
      class="textfield-dark-theme"
      label="Facebook url"
      bind:value={contact.facebook.url} />
    <Textfield
      class="textfield-dark-theme"
      label="Instagram label"
      bind:value={contact.instagram.label} />
    <Textfield
      class="textfield-dark-theme"
      label="Instagram url"
      bind:value={contact.instagram.url} />
    <Textfield
      class="textfield-dark-theme"
      label="Priority"
      type="number"
      message="1 will be shown first in the grid"
      bind:value={contact.priority} />
    <div>
      <h1>
        Image
        {#if contact.imageUrl}
          <EditButton on:click={() => (showFileUploader = true)} />
        {/if}
      </h1>
      {#if showFileUploader}
        <input type="file" bind:files required />
      {:else}
        <img src={contact.imageUrl} alt={contact.name} />
      {/if}
    </div>
    <div>

      {#if $isAdding}
        <Button raised color="#388e3c" on:click={onContactSave}>Save</Button>
      {/if}

      {#if $isEditing}
        <Button raised color="#388e3c" on:click={onContactUpdate}>
          Update
        </Button>
      {/if}

      <Button color="rgba(255, 255, 255, 0.7)" outlined on:click={clear}>
        Cancel
      </Button>
    </div>
  </div>
</section>
