<script>
  import { getContext, createEventDispatcher } from "svelte";
  import { Button } from "svelte-mui/src";
  import { slide } from "svelte/transition";

  let dispatch = createEventDispatcher();

  let hiResFiles;
  let hiResFile;
  let thumbnailFiles;
  let thumbnailFile;

  let pressItem = {};

  let pressStoragePath = "press/";
  let pressCollection = getContext("firebase")
    .firestore()
    .collection("press");
  let storageRef = getContext("firebase")
    .storage()
    .ref();

  $: if (hiResFiles) hiResFile = hiResFiles[0];
  $: if (thumbnailFiles) thumbnailFile = thumbnailFiles[0];

  function uploadFiles() {
    uploadHiResImage()
      .then(uploadThumbnail)
      .then(addPressItem)
      .then(clear);
  }

  //   function upload(file, ) {

  //   }

  function uploadHiResImage() {
    return storageRef
      .child(pressStoragePath + hiResFile.name)
      .put(hiResFile)
      .then(upload => upload.ref.getDownloadURL())
      .then(url => (pressItem.hiRes = { url: url, name: hiResFile.name }));
  }

  function uploadThumbnail() {
    return storageRef
      .child(pressStoragePath + thumbnailFile.name)
      .put(thumbnailFile)
      .then(upload => upload.ref.getDownloadURL())
      .then(
        url => (pressItem.thumbnail = { url: url, name: thumbnailFile.name })
      );
  }

  function addPressItem() {
    pressCollection.add(pressItem);
  }

  function clear() {
    pressItem = {};
    hiResFiles = null;
    thumbnailFiles = null;
    dispatch("hide-form");
  }
</script>

<style>
  form {
    padding: 2rem;
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 414px) {
    form {
      grid-template-columns: 1fr;
    }
  }
</style>

<form on:submit|preventDefault={uploadFiles} transition:slide>
  <section>
    <h1>Hi-Res</h1>
    <input type="file" bind:files={hiResFiles} />
  </section>
  <section>
    <h1>Thumbnail</h1>
    <input type="file" bind:files={thumbnailFiles} />
  </section>
  <section>
    <Button type="submit" raised color="#388e3c">Upload</Button>
    <Button
      type="button"
      color="rgba(255, 255, 255, 0.7)"
      outlined
      on:click={clear}>
      Cancel
    </Button>
  </section>
</form>
