<script>
  import { Collection } from "sveltefire";
  import Show from "Components/shows/Show.svelte";
  import { fade } from "svelte/transition";
  import { media } from "../media.store";

  export let heading;
  export let upcoming = false;

  let today = new Date();
  let path = "shows";

  function getQuery(ref) {
    if (upcoming)
      return ref.where("date", ">=", today.getTime()).orderBy("date");
    else return ref.where("date", "<", today.getTime()).orderBy("date", "desc");
  }
</script>

<style>
  article {
    display: grid;
    justify-items: center;
  }

  section {
    max-width: 1000px;
    width: 100%;
  }

  h1 {
    padding: 1rem;
  }

  div {
    padding-left: 1rem;
  }

  .mobile {
    text-align: center;
  }
</style>

<Collection {path} query={getQuery} let:data={shows} let:ref>
  <article>
    <section>
      <h1 in:fade class:mobile={$media.mobile}>{heading}</h1>
      {#each shows as show, index (show.id)}
        {#if !shows.length && upcoming}
          <div>No shows booked at the moment</div>
        {:else}
          <Show
            {...show}
            {index}
            listLength={shows.length}
            on:delete={() => ref.doc(show.id).delete()} />
        {/if}
      {/each}
    </section>
  </article>
</Collection>
