<script>
  import AlbumThumbnail from "Components/music/AlbumThumbnail.svelte";
  import { navigate } from "svelte-routing";
  import { Collection } from "sveltefire";
  import { getContext } from "svelte";

  let path = "albums";
  let query = ref => ref.orderBy("year", "desc");

  function remove(event) {
    const album = event.detail;

    const firebase = getContext("firebase");
    const storage = firebase.storage();
    const imageRef = storage.refFromURL(album.src);

    imageRef.delete().then(() => {
      album.ref.delete();
    });
  }

  function goTo(album) {
    navigate("/albums/" + album.id);
  }
</script>

<style>
  div {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 24px;
    padding: 24px;
  }
</style>

<Collection {path} {query} let:data={albums}>
  <div>
    {#each albums as album, index (album)}
      <AlbumThumbnail
        {album}
        {index}
        on:delete={remove}
        on:click={goTo(album)} />
    {/each}
  </div>
</Collection>
