<script>
  import { Button, Icon } from "svelte-mui/src";

  export let desktop = false;
  let color = "#343434";
  let iconColor = "rgba(255, 255, 255, 0.87)";
  let path = "M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z";
</script>

{#if desktop}
  <Button raised {color} on:click>
    <Icon color={iconColor} {path} />
    Download
  </Button>
{:else}
  <Button icon on:click>
    <Icon color={iconColor} {path} />
  </Button>
{/if}
