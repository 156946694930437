import { writable } from 'svelte/store';

const emptyShowItem = {
  date: '',
  venue: '',
  city: '',
  country: '',
  link: ''
};

const formWritable = writable(emptyShowItem);

export const showFormData = {
  subscribe: formWritable.subscribe,
  set: formWritable.set,
  clear: () => {
    isEditing.set(false);
    isAdding.set(false);
    formWritable.set(emptyShowItem);
  }
};
export const isEditing = writable(false);
export const isAdding = writable(false);
